import React from 'react';

import Application from 'components/pages/home/application';
import Features from 'components/pages/home/features';
import Hero from 'components/pages/home/hero';
import Special from 'components/pages/home/special';
import Support from 'components/pages/home/support';
import MainLayout from 'layouts/main';

const HomePage = () => (
  <MainLayout language="de">
    <Hero
      title="Die Plattform für <span><div>grünes</div></span> und dezentrales&nbsp;Cloud-Computing"
      advantages={{
        title: 'Vorteile',
        features: [
          {
            text: 'Wir bieten nachhaltiges Cloud Computing durch Direktversorgung unserer Rechenzentren mit regionalem Grünstrom aus Wind und Sonne.',
          },
          {
            text: 'Volle Datenhoheit und EU-DSGVO Compliance an deutschen Standorten deiner Wahl - sicher und geschützt im Schwarmverbund.',
          },
          {
            text: 'Kundennahe Edge-Cloud-Services für innovative Unternehmen mit kritischen und datenintensiven Anwendungen.',
          },
        ],
        newsletter: 'Keine Updates mehr verpassen',
      }}
      features={[
        {
          iconName: 'kubernetesCluster',
          title: 'Managed Kubernetes Cluster',
          description: `Microservices sind das Werkzeug deiner Wahl? Mit unserem Managed Kubernetes Cluster bieten wir die passende Lösung. Starte im Handumdrehen deine Applikationen und lasse die Hürden der Administration von Kubernetes einfach hinter dir.`,
        },
        {
          iconName: 'serverless',
          title: 'Serverless und<br />FaaS',
          description: `Du entwickelst Software und willst dich voll und ganz auf deinen Code konzentrieren? Mit FaaS befreien wir deinen Workflow von jeglicher Last der Infrastruktur und bieten dir eine reibungslose und intuitive Cloud-Umgebung. Nur du, dein Code und dein Kaffee.`,
        },
        {
          iconName: 'computing',
          title: `Edge-Cloud<br />Computing`,
          description: `Deine wertvollen Daten haben keine Lust auf lange Rundreisen? Unsere verteilten Edge Rechenzentren warten vielleicht schon direkt um die Ecke auf dich. Niedrige Latenz und volle Datensouveränität beschleunigen deine Daten und dein Business.`,
        },
      ]}
    />
    <Application
      language="de"
      title="Simples Infrastruktur Management"
      tabs={[
        { title: 'Standorte und Cluster' },
        { title: 'Status Energie und Latenz ' },
        { title: 'Autom. Zuordnung der Ressourcen' },
      ]}
    />
    <Features
      items={[
        {
          title: 'Nachhaltig',
          animation: 'sustainable',
          description:
            'Wir nutzen bestehende Infrastruktur von Wind- und Solarparks für den Betrieb unsere Edge-Rechenzentren. Durch die lokale Direktversorgung mit günstigem Grünstrom ermöglichen wir starke Synergien und hohe Effizienz. Kein Greenwashing. The real deal.',
        },
        {
          title: 'Dezentral',
          animation: 'decentralized',
          description:
            'Regionale Edge-Cloud-Services für innovative Unternehmen mit latenzsensiblen und kritischen IT-Workloads. Unsere dezentralen Edge-Rechenzentren sind nahe bei unseren Kunden und noch näher an den regenerativen Energiequellen.',
        },
        {
          title: 'Resilient',
          animation: 'resilient',
          description:
            'Unser smarter Schwarm verteilter Rechenzentren ermöglicht Georedundanz, Failover-Szenarien, dynamische Workload-Migration und verschlüsselte Daten. Automatisiert, sicher und robust in einem Rechenzentren-Cluster deiner Wahl.',
        },
      ]}
    />
    <Special
      title="Was zeichnet uns aus?"
      description="Unsere Schwarmplattform orchestriert das Potential der Vielen"
      features={{
        title: 'Wichtige Aspekte',
        items: [
          {
            text: 'Die dezentrale Plattform erschafft ein selbstorganisierendes und resilientes System.',
          },
          {
            text: 'Das integrierte Energiemanagement ermöglicht standortübergreifende Optimierung.',
          },
          {
            text: 'Ohne es zu merken, wird dein Unternehmen ein aktiver Teil der Energiewende!',
          },
        ],
      }}
    />
    <Support
      title="Unsere starken Partner und Unterstützer auf dem Weg zur grünen Edge-Cloud"
      logosTopDescription="Partner und Unterstützer"
      logosBottomDescription="Mitgliedschaften"
    />
  </MainLayout>
);

export default HomePage;
